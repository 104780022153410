var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"30px"}},[(_vm.newsList.length > 0)?_c('div',_vm._l((_vm.newsList),function(item,i){return _c('el-row',{key:i,staticClass:"list"},[(i != 0)?_c('div',[(item.year - _vm.newsList[i - 1].year == -1)?_c('div',{staticClass:"year"},[_vm._v(" "+_vm._s(item.year)+" ")]):_vm._e()]):_c('div',{staticClass:"year"},[_vm._v(" "+_vm._s(item.year)+" ")]),_c('el-col',{attrs:{"span":2}}),_c('el-col',{attrs:{"span":14}},[_c('router-link',{key:i,staticClass:"items",attrs:{"to":{
          path: 'solution',
          name: 'solution',
          query: {
            nid: item.id,
            cid: _vm.cid,
            sid: _vm.sid,
            degree: _vm.degree,
          },
        }}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title)+" ")])])],1),_c('el-col',{attrs:{"span":4,"offset":6}},[_vm._v(" "+_vm._s(item.add_time)+" ")])],1)}),1):_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("暂无信息！")]),_c('div',{staticClass:"pagination"},[_c('el-pagination',{staticClass:"page-box",attrs:{"hide-on-single-page":true,"page-size":_vm.pageSize,"total":_vm.total,"background":"","layout":"prev, pager, next"},on:{"current-change":_vm.pageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }