<template>
  <div>
    <el-table :data="tableData" :header-cell-style="rowClass" style="width: 100%" size="small"
      :row-class-name="setRowBackground">
      <el-table-column prop="year" label="年份" width="80">
      </el-table-column>

      <el-table-column prop="specialty_name" label="专业名称" width="280">
        <template slot-scope="scope">
          [{{ scope.row.specialty_id | interpo }}]{{ scope.row.specialty_name }}
        </template>
      </el-table-column>
      <el-table-column prop="plan_num" label="计划数" width="60">
      </el-table-column>
      <el-table-column prop="recruitment_num" label="投档数" width="60">
      </el-table-column>
      <el-table-column prop="order_rule" label="录取规则" width="220">
        <template slot-scope="scope">
          文化分× {{ scope.row.w_scale / 100 | filterfive }} + 专业分 × {{ scope.row.z_scale / 100 | filterfive
          }}
        </template>
      </el-table-column>
      <el-table-column prop="batch" label="批次">
        <template slot-scope="scope">
          <div v-for="(item, i) in batchList" :key="i">
            <div v-if="item.id == scope.row.batch"> {{ item.name }} </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="min_score" label="投档分*">

      </el-table-column>
      <el-table-column label="投档分线差*" width="90">
        <template slot-scope="scope">
          {{ scope.row.min_score - fencha(scope.row) | filterTwo }}
        </template>
      </el-table-column>
      <el-table-column label="换算分*">
        <template slot-scope="scope">
          {{ equalScore(scope.row) | filterTwo }}
        </template>
      </el-table-column>
      <el-table-column label="换算分线差*" width="90">
        <template slot-scope="scope">
          {{ (equalScore(scope.row) - fencha(scope.row)) | filterTwo }}
        </template>
      </el-table-column>

      <el-table-column label="分差*" width="60">
        <template slot-scope="scope">
          {{ (equalScore(scope.row) - scope.row.min_score) | filterTwo }}
        </template>
      </el-table-column>
    </el-table>

    <div class="remarks">
      <p>投档分：该院校/专业当年的投档综合分的最低分</p>
      <p>投档分线差：按照当年该批次文化课和专业课省控线成绩，根据该院校/专业的录取规则，得出省控线综合分，投档综合分减去省控线综合分即为投档分线差</p>
      <p>换算分：按照你的文化课和专业课成绩换算到当年成绩，根据当年该院校/专业的录取规则，得出的综合分</p>
      <p>换算分线差：当年的换算分减去当年省控线即为换算分线差</p>
      <p>分差：当年换算分减去当年投档分即为分差</p>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ['oldScoreArt', 'oldScore'],
  name: '',
  data() {
    return {
      tableData: [],
      batchList: [
        {
          id: "1",
          name: "本科一批",
        },
        {
          id: "2",
          name: "本科二批",
        },
        {
          id: "3",
          name: "高职高专批",
        },
        {
          id: "4",
          name: "提前批",
        },
        {
          id: "5",
          name: "本科A段",
        },
        {
          id: "6",
          name: "本科B段",
        },
        {
          id: "7",
          name: "本科",
        }
      ],
      newLine: [],
      oldLine: []
    };
  },

  computed: {
    ...mapState(["userInfo"]),
    fencha() {
      return function (item) {
        if (this.oldLine[0]) {
          let index = ''
          switch (item.batch) {
            case 5:
              switch (item.year) {
                case 2023:
                  index = 0;
                  break
                case 2022:
                  index = 3;
                  break
                case 2021:
                  index = 6;
                  break
                case 2020:
                  index = 9;
                  break
              }
              break
            case 6:
              switch (item.year) {
                case 2023:
                  index = 1;
                  break
                case 2022:
                  index = 4;
                  break
                case 2021:
                  index = 7;
                  break
                case 2020:
                  index = 10;
                  break
              }
              break
            case 3:
              switch (item.year) {
                case 2023:
                  index = 2;
                  break
                case 2022:
                  index = 5;
                  break
                case 2021:
                  index = 8;
                  break
                case 2020:
                  index = 11;
                  break
              }

          }
          if (this.oldLine[index]) {
            return (item.w_scale * this.oldLine[index].curture_score + item.z_scale * this.oldLine[index].major_score) / 100
          } else {
            return ''
          }
        } else {
          return ''
        }
      }
    },
    equalScore() {
      return function (item) {
        let index = ''
        switch (item.year) {
          case 2023:
            index = 1
            break
          case 2022:
            index = 2
            break
          case 2021:
            index = 3
            break
          case 2020:
            index = 4
            break
        }
        return (item.w_scale * this.oldScore[index] + item.z_scale * this.oldScoreArt[index]) / 100
      }
    }
  },
  filters: {
    filterTwo(item) {
      return item.toFixed(0)
    },
    filterfive(item) {
      if (item === Math.floor(item)) {
        return item
      } else {
        return item.toFixed(3)
      }
    }
  },
  created() {
    this.getData()
    this.getLineList()
  },
  methods: {
    rowClass() {
      return 'background:#f4f7fc;color:black'
    },
    getData() {
      let data = {
        cid: this.$route.query.cid,
        is_wenli: this.userInfo.is_wenli
      }
      this.$fecth.post('art_specialty_score/getSpecialtyData', data).then((res) => {
        this.tableData = res
      })
    },
    setRowBackground(item) {
      if (item.row.year == 2021 || item.row.year == 2023) {
        return 'warning-row'
      }
      // console.log(item)
    },

    // 获取批次线
    getLineList() {
      this.$fecth
        .post("art_specialty_score/getArtLineScore", {
          year: 2023
        })
        .then((res) => {
          this.oldLine = res
          console.log(this.oldLine)
        });
    },
  },
};
</script>

<style lang='less'>
.el-table .warning-row {
  background: #e7f1fb;
}
</style>

<style lang="less" scoped>
.remarks {
  padding: 20px;
  color: #606266;
  font-size: 12px;
}
</style>
