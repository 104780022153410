import { render, staticRenderFns } from "./CollegeNews.vue?vue&type=template&id=d95fc7ec&scoped=true&"
import script from "./CollegeNews.vue?vue&type=script&lang=js&"
export * from "./CollegeNews.vue?vue&type=script&lang=js&"
import style0 from "./CollegeNews.vue?vue&type=style&index=0&id=d95fc7ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d95fc7ec",
  null
  
)

export default component.exports