<template>
  <div>
    <el-table :data="tableData" :header-cell-style="rowClass" style="width: 100%" size="small">
      <el-table-column prop="school_id" label="院校代码" width="80">
      </el-table-column>
      <el-table-column prop="specialty_id" label="专业代码" width="80">
      </el-table-column>
      <el-table-column prop="specialty_name" label="专业名称" width="240">
      </el-table-column>
      <el-table-column prop="plan_num" label="专业计划" width="80">
      </el-table-column>
      <el-table-column prop="tuition" label="收费标准" width="100">
        <template slot-scope="scope">
          {{ scope.row.tuition }}元/年
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="lqgz" label="录取规则" width="240">
      </el-table-column>
      <el-table-column prop="advance_batch" label="批次">
        <template slot-scope="scope">
          <div v-for="(item, i) in batchList" :key="i">
            <div v-if="item.id == scope.row.advance_batch"> {{ item.name }} </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="综合分">
        <template slot-scope="scope">
          <div v-for="(item, i) in batchList" :key="i">
            <div v-if="item.id == scope.row.advance_batch"> {{ item.name }} </div>
          </div>
        </template>
      </el-table-column> -->
    </el-table>

  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      tableData: [],
      batchList: [
        {
          id: "1",
          name: "本科一批",
        },
        {
          id: "2",
          name: "本科二批",
        },
        {
          id: "3",
          name: "高职高专批",
        },
        {
          id: "4",
          name: "提前批",
        },
        {
          id: "5",
          name: "本科A段",
        },
        {
          id: "6",
          name: "本科B段",
        },
        {
          id: "7",
          name: "本科",
        }
      ],
    };
  },
  computed: {
  },
  created() {
    this.getData()
  },
  methods: {
    rowClass() {
      return 'background:#f4f7fc;color:black'
    },
    getData() {
      let data = {
        cid: this.$route.query.cid
      }
      this.$fecth.post('art_plan/getData', data).then((res) => {
        this.tableData = res.lists
      })
    }
  },
};
</script>

<style scoped lang='less'></style>