<template>
  <div class="artCollege">
    <div class="top">
      <el-row class="school_info">
        <el-col :span="4">
          <img :src="school.logo" alt="" style="
              width: 120px;
              position: relative;
              border-radius: 100px;
              top: 31px;
              left: 70px;
            " />
        </el-col>
        <el-col :span="20" style="padding-left: 40px">
          <el-row style="margin-top: 10px">
            <el-col :span="24">
              <span style="font-size: 18px; padding-right: 30px">{{
          school.name
        }}</span>
              <span style="padding-right: 30px">{{ school.address }}</span>
              <span style="color: red"><i class="iconfont icon-hot" />{{ school.school_hot }}</span>
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-col :span="24">
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.degree == 0 ? "本科" : "专科" }}
              </span>
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.school_nature }}
              </span>
              <span style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.school_type }}
              </span>
              <span v-for="(item, i) in school.school_tip_id" :key="i" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ item }}
              </span>
              <span v-if="school.sg_type" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.sg_type }}
              </span>
              <span v-if="school.sg_specialty" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 5px;
                ">
                {{ school.sg_specialty }}
              </span>
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <el-col :span="24">
              <a v-if="school.school_url" :href="school.school_url" target="_blank" class="link">官方网站</a>
              <a v-if="school.school_admissions_url" :href="school.school_admissions_url" target="_blank"
                class="link">招生网站</a>
              <a v-if="school.school_panoramic" :href="school.school_panoramic" target="_blank" class="link">全景校园</a>
            </el-col>
          </el-row>
          <el-row>
            <span>学校地址: {{ school.full_address }}</span><span style="margin-left: 15px">
              咨询电话：{{ school.phone }}</span>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="main">
      <div class="title">
        我的成绩
      </div>
      <div class="info">
        <el-row>
          <el-col :span="9">
            <span>科类:</span>
            <span class="info_2">{{ userInfo.is_wenli == 1 ? '文科' : '理科' }}</span>
            <span>专业课分:</span>
            <span class="info_2">{{ userInfo.z_score }}分</span>
            <span>位次:&nbsp;</span>
            <span class="info_2">{{ oldScoreArt[0] }}</span>
          </el-col>

          <el-col :span="9">
            <span>文化课分:</span>
            <span class="info_2">{{ userInfo.score }}分</span>
            <span>位次:&nbsp;</span>
            <span class="info_2">{{ userInfo.ranking }}</span>
          </el-col>
        </el-row>
      </div>

      <div class="title">
        招生计划
      </div>
      <art-plan></art-plan>

      <div class="title">
        历年录取
      </div>
      <art-specialt-score :oldScoreArt="oldScoreArt" :oldScore="oldScore"></art-specialt-score>

      <div class="title">
        招生章程
      </div>
      <college-news></college-news>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import CollegeNews from './components/CollegeNews'
import ArtPlan from './components/ArtPlan.vue'
import ArtSpecialtScore from './components/ArtSpecialtyScore'
export default {
  name: "College",
  data() {
    return {
      cat: 0,
      school: [],
      currentTabComponent: "CollegeInfo",
      oldScoreArt: ['', '', '', '',''],
      oldScore: []
    };
  },
  components: {
    CollegeNews, ArtPlan, ArtSpecialtScore
  },
  computed: { ...mapState(["userInfo"]) },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //获取学校信息
    this.$fecth
      .get("school/schoolIndex", { params: { id: this.$route.query.cid } })
      .then((res) => {
        this.school = res;
      });
    this.getEqualScore();
  },
  methods: {
    getEqualScore() {
      this.$fecth
        .post("art_specialty_score/getArtEqualScore", {
          is_wenli: this.userInfo.is_wenli,
          z_score: this.userInfo.z_score,
          score: this.userInfo.score,
        })
        .then((res) => {
          if (res) {
            this.oldScoreArt = res.art
            this.oldScore = res.ordinary
          }
        });
    }
  },
};
</script>

<style lang="less" scoped>
.artCollege {
  .top {
    background: #443fa3;
    min-height: 216px;

    .school_info {
      width: 1200px;
      margin: 0 auto;
      padding-top: 20px;
      color: #ffffff;

      a {
        display: inline-block;
        color: #0d55bc;
        text-decoration: none;
        background: white;
        height: 27px;
        line-height: 27px;
        padding: 0 10px;
        border-radius: 30px;
        margin: 0px 7px;
      }
    }
  }

  .main {
    width: 1200px;
    margin: 0 auto;

    .title {
      border-left: 5px solid #519cea;
      text-indent: 0.5rem;
      margin: 20px 0;
      font-size: 18px;
      color: #4d4d4d;
      display: inline-block;
    }

    .remarks {
      display: inline-block;
      font-size: 12px;
      color: grey;
    }

  }

  .info {
    font-size: 16px;
    position: relative;

    span {
      display: inline-block;
    }

    margin: 20px 0;

    .info_2 {
      color: #1787e0;
      margin-right: 25px;
    }
  }


}
</style>